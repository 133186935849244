const PAGINATION = Object.freeze({
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_PAGE_NO: 1
});

const OPERATIONS = Object.freeze({
    ADD:"ADD",
    EDIT:"EDIT",
    DELETE:"DELETE",
    VIEW:"VIEW"
})

const MODULE_SLUG = Object.freeze({
    USER: "USER",
    STATIC_PAGE:"STATIC_PAGE",
    TASK_TYPE:"TASK_TYPE"
});

const APP_ENV_TYPE = Object.freeze({
    LOCAL: 'local',
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
});

const TOGGLE = Object.freeze({
    ACTIVE:"Active",
    INACTIVE:"Inactive"
});

const ADMIN_CONFIG = Object.freeze({
    password: {
        min: 8,
        max: 20,
    }
});

module.exports = {
    PAGINATION,
    OPERATIONS,
    MODULE_SLUG,
    APP_ENV_TYPE,
    API_BASEURL: process.env.REACT_APP_API_BASEURL,

    BREADCRUMB_SEPARATOR: ">",

    LOGIN_DATA: {
        name: "eve holt",
        email: "admin@horizon.com",
        password: "Admin@123"
    },
    REDUX_LOCAL_STORE_KEY: 'horizon-teacher-planner-data',
    TOGGLE,
    ADMIN_CONFIG
}